import { useErrorStore } from "../store";

export const handleApiError = (
  message: string,
  extra: Record<string, unknown>,
): void => {
  useErrorStore.getState().addApiError({
    extra,
    message,
  });
};
