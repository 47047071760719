import { ErrorMessageComponent } from "@/features/api/domain/ErrorMessageComponent";
import { PaymentTag } from "@/pages/sales/components/PaymentTag";
import { CreateSalePayment } from "@meditect/medibase-api-client";
import { HStack, Text, VStack } from "@meditect/medibase-components";

interface SalesCannotCloseSaleWithInvalidPaymentErrorExtra {
  errors: {
    error: string;
    payment: CreateSalePayment;
  }[];
}

const labels: Record<string, string> = {
  bank: "La banque",
  creditMetadata: "Le client",
  "creditMetadata.organization": "Le client",
  "creditMetadata.person": "Le client",
  insuranceMetadata: "Le patient",
  "insuranceMetadata.contractPolicy": "La couverture",
  "insuranceMetadata.voucher": "Le numéro de bon",
  phoneOperator: "L'opérateur",
};

export const SalesCannotCloseSaleWithInvalidPaymentError: ErrorMessageComponent<
  SalesCannotCloseSaleWithInvalidPaymentErrorExtra
> = ({ extra }) => {
  return (
    <>
      <Text mb={4}>
        Des informations sont manquantes pour clôturer la vente. Vous devez
        compléter les règlements suivants :
      </Text>
      <VStack alignItems="stretch" gap={4} pl={4}>
        {extra.errors.map(({ error, payment }, index) => {
          return (
            <HStack key={index}>
              <PaymentTag type={payment.type} />
              <Text>{labels[error]}</Text>
            </HStack>
          );
        })}
      </VStack>
    </>
  );
};
