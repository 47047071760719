import { Text } from "@meditect/medibase-components";

export const SalesCannotGetInvoiceEMECeFError = () => {
  return (
    <Text>
      La récupéreration de la vente sur la plateforme e-MECeF a généré une
      erreur
    </Text>
  );
};
