import * as Sentry from "@sentry/react";
import { User } from "firebase/auth";
import { createContext, type ReactElement, type ReactNode } from "react";

import type { Claims } from "../auth/components/ClaimsContext";

export interface SentryContextValue {
  scope: Sentry.Scope;
  setMetadata: (user: User, claims: Claims) => void;
}

export const SentryContext = createContext<SentryContextValue>({
  scope: Sentry.getCurrentScope(),
  setMetadata: () => {
    console.warn("Not implemented setMetadata in SentryContext !");
  },
});

export const SentryProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const scope = Sentry.getCurrentScope();

  return (
    <SentryContext.Provider
      value={{
        scope,
        setMetadata: (user, claims) => {
          scope.setUser({
            email: user.email ?? "",
            id: claims.domainId,
            username: user.displayName ?? "",
          });
          if (claims.pharmacyId) {
            scope.setTag("pharmacy", claims.pharmacyId);
          }
        },
      }}
    >
      {children}
    </SentryContext.Provider>
  );
};
