import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { keepPreviousData, QueryClient } from "@tanstack/react-query";

import { FIVE_MINUTES } from "./features/query/const";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: FIVE_MINUTES, // 5 min
      placeholderData: keepPreviousData,
      staleTime: 120_000,
    },
  },
});

export const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});
