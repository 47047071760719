import { ErrorMessageComponent } from "@/features/api/domain/ErrorMessageComponent";
import { PaymentTag } from "@/pages/sales/components/PaymentTag";
import { formatContractPolicyTitle } from "@/pages/sales/utils/formatContractPolicyTitle";
import { ContractPolicy, Payment } from "@meditect/medibase-api-client";
import { HStack, Text, VStack } from "@meditect/medibase-components";

interface SalesCannotCloseSaleWithSameContractPolicyTwiceErrorExtra {
  errors: {
    payment: Payment;
  }[];
}

export const SalesCannotCloseSaleWithSameContractPolicyTwiceError: ErrorMessageComponent<
  SalesCannotCloseSaleWithSameContractPolicyTwiceErrorExtra
> = ({ extra }) => {
  return (
    <>
      <Text mb={4}>
        Impossible de clôturer la vente avec des contrat d'assurance en double.
        Vous devez supprimer les contrats d'assurance en double ci-après :
      </Text>
      <VStack alignItems="stretch" gap={4} pl={4}>
        {extra.errors.map(({ payment }, index) => {
          return (
            <HStack key={index}>
              <PaymentTag type={payment?.type} />
              <Text>
                {formatContractPolicyTitle(
                  payment.insuranceMetadata?.contractPolicy as ContractPolicy,
                )}
              </Text>
            </HStack>
          );
        })}
      </VStack>
    </>
  );
};
