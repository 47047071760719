import {
  AssociatePharmacyProduct,
  CreatePharmacyProduct,
  GetManyPharmacyProductsQueryParams as GetManyPharmacyProductsQueryParameters,
  GetManyQueryParams as GetManyQueryParameters,
  PharmacyProduct,
  PharmacyProductHistory,
  PharmacyProductPage,
  PharmacyProductStats,
  PharmacyProductSummaryPage,
  Timespan,
  UpdatePharmacyProduct,
} from "../dtos";
import { GetManyPharmacyProductByQuantity } from "../dtos/GetManyPharmacyProductByQuantity";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithGet } from "./mixins/WithGet";
import { PharmacyProductStorageEndpoint } from "./PharmacyProductStorageEndpoint";

export interface GetPharmacyProductsBatch {
  ids: string[];
}

export class Endpoint extends BaseEndpoint("pharmacy-products") {
  readonly storages = new PharmacyProductStorageEndpoint(this.client);

  createBatch(products: CreatePharmacyProduct[]): Promise<PharmacyProduct[]> {
    return this.request("batch", { body: products, method: "POST" });
  }

  getPage(
    query?: GetManyPharmacyProductsQueryParameters,
  ): Promise<PharmacyProductSummaryPage> {
    return this.request("", { query });
  }

  findAll(query?: { storageId?: string }): Promise<PharmacyProduct[]> {
    return this.request("storages", { query });
  }

  getStats(
    query?: GetManyPharmacyProductsQueryParameters,
  ): Promise<PharmacyProductStats> {
    return this.request("stats", { query });
  }

  getHistory(id: string, timespan: Timespan): Promise<PharmacyProductHistory> {
    return this.request(`${id}/history`, { query: { timespan } });
  }

  associate(
    id: string,
    body: AssociatePharmacyProduct,
  ): Promise<PharmacyProduct> {
    return this.request(`${id}/associate`, { method: "POST", body });
  }

  getAll(
    query: Omit<GetManyQueryParameters, "size">,
  ): Promise<PharmacyProductPage> {
    return this.request("all", { query });
  }

  getAllByStockQuantity(
    query: GetManyPharmacyProductByQuantity,
  ): Promise<PharmacyProductPage> {
    return this.request("by-stock-quantity", { query });
  }

  getByDeliveryRecordId(id: string): Promise<PharmacyProduct> {
    return this.request(`deliveryRecord/${id}`);
  }
}

const EditEndoint = WithEdit<
  typeof Endpoint,
  PharmacyProduct,
  CreatePharmacyProduct,
  UpdatePharmacyProduct
>(Endpoint);

export const PharmacyProductEndpoint = WithGet<
  typeof EditEndoint,
  PharmacyProduct
>(EditEndoint);
