import { create } from "zustand";

export interface ApiError<T extends object = Record<string, unknown>> {
  extra: T;
  message: string;
}

interface ApiErrorState {
  addApiError: (error: ApiError) => void;
  errors: ApiError[];
  popApiError: () => void;
}

// Create the store
const useErrorStore = create<ApiErrorState>((set) => ({
  addApiError: (payload) =>
    set((state) => ({
      errors: [...state.errors, payload],
    })),
  errors: [],
  popApiError: () => set({ errors: [] }),
}));

export { useErrorStore };
