import { User } from "firebase/auth";
import { createContext } from "react";

export interface UserContextValue {
  user: null | User;
}

export const UserContext = createContext<UserContextValue>({
  user: null,
});
