import { ApiError } from "@/features/api/store";
import { isEmptySale } from "@/pages/sales/utils/localSale";
import {
  PharmacyProductWithStockItemMappings,
  Sale,
  SaleRecord,
} from "@meditect/medibase-api-client";

import { Slice } from "../boundStore";

export type LocalSaleStatus = "ERROR" | "LOCAL" | "ONGOING" | "SUBMITTING";

export type LocalSale = {
  errors?: ApiError[]; // use ApiError code to trigger `ApiErrorModal`
  isOpen: boolean;
  sale: Sale;
  status: LocalSaleStatus;
};

export type SaleSlice = {
  closeSale: (sale?: Sale) => void;
  createLocalSale: (sale: Sale) => void;
  endSale: (sale?: Sale) => void;
  handleSaleError: (sale: Sale | undefined, error: ApiError) => void;
  openSale: (sale?: Sale) => void;
  removeSale: (sale?: Sale) => void;
  resetSales: () => void;
  sales: LocalSale[];
  updateLocalSale: (sale?: Sale) => void;
  updateLocalSalesFromPharmacyProductMappings: (
    pharmacyProduct: PharmacyProductWithStockItemMappings,
  ) => void;
};

export const createSaleSlice: Slice<SaleSlice> = (set) => ({
  closeSale: (sale) => {
    set(({ sale: state }) => {
      checkIfExist(
        {
          ifExist: (sale, index) => {
            if (isEmptySale(state.sales[index].sale)) {
              state.sales = state.sales.filter(
                (sale) => sale.sale.id !== state.sales[index].sale.id,
              );
              return;
            }
            state.sales[index].isOpen = false;
          },
          ifNoExist: (sale) => {
            state.sales.push({ isOpen: true, sale, status: "ONGOING" });
          },
        },
        { sale, sales: state.sales },
      );
    });
  },

  // Setter
  createLocalSale: (sale) => {
    set(({ sale: state }) => {
      checkIfExist(
        {
          ifNoExist: () => {
            state.sales.push({ isOpen: false, sale, status: "LOCAL" });
          },
        },
        { sale, sales: state.sales },
      );
    });
  },
  endSale: (sale) => {
    set(({ sale: state }) => {
      checkIfExist(
        {
          ifExist: (sale, index) => {
            state.sales[index].sale = sale;
            state.sales[index].isOpen = false;
            state.sales[index].status = "SUBMITTING";
            state.sales[index].errors = [];
          },
        },
        { sale, sales: state.sales },
      );
    });
  },
  handleSaleError: (sale, error) => {
    set(({ sale: state }) => {
      checkIfExist(
        {
          ifExist: (_, index) => {
            state.sales[index].status = "ERROR";
            state.sales[index].errors = [error];
          },
        },
        { sale, sales: state.sales },
      );
    });
  },
  openSale: (sale) => {
    set(({ sale: state }) => {
      checkIfExist(
        {
          ifExist: (_, index) => {
            state.sales[index].isOpen = true;
          },
          ifNoExist: (sale) => {
            state.sales.push({ isOpen: true, sale, status: "ONGOING" });
          },
        },
        { sale, sales: state.sales },
      );
    });
  },
  removeSale: (sale) => {
    set(({ sale: state }) => {
      checkIfExist(
        {
          ifExist: (_, index) => {
            state.sales = state.sales.filter(
              (sale) => sale.sale.id !== state.sales[index].sale.id,
            );
          },
        },
        { sale, sales: state.sales },
      );
    });
  },
  resetSales: () => {
    set(({ sale: state }) => {
      state.sales = [];
    });
  },
  // State
  sales: [],
  updateLocalSale: (sale) => {
    set(({ sale: state }) => {
      checkIfExist(
        {
          ifExist: (sale, index) => {
            state.sales[index].sale = {
              ...sale,
              paymentTypes: [
                ...new Set(sale.payments.map((payment) => payment.type)),
              ],
              referenceCount:
                sale.records?.reduce<
                  { packagingId?: string; productId: string }[]
                >((p, v) => {
                  if (
                    !p.some(
                      ({ packagingId, productId }) =>
                        productId === v.stockItem.pharmacyProductId &&
                        packagingId === v.stockItem.packaging?.id,
                    )
                  ) {
                    p.push({
                      packagingId: v.stockItem.packaging?.id,
                      productId: v.stockItem.pharmacyProductId,
                    });
                  }
                  return p;
                }, []).length + (sale.vouchers?.length ?? 0),
              total:
                (sale.records?.reduce((p, v) => p + v.price * v.quantity, 0) ??
                  0) +
                (sale.vouchers?.reduce((p, v) => p + v.price * v.quantity, 0) ??
                  0),
              units:
                (sale.records?.reduce((p, v) => p + v.quantity, 0) ?? 0) +
                  sale.vouchers?.reduce((p, v) => p + v.quantity, 0) ?? 0,
            };
          },
        },
        { sale, sales: state.sales },
      );
    });
  },
  updateLocalSalesFromPharmacyProductMappings: (pharmacyProduct) => {
    const changedStockItemIds = Object.keys(pharmacyProduct.mappings ?? {});

    set(({ sale: state }) => {
      const salesWithUpdatedProduct = state.sales
        .map((s) => s.sale)
        .filter((sale) =>
          sale.records.some((record) =>
            changedStockItemIds.includes(record.stockItem.id),
          ),
        );
      for (const sale of salesWithUpdatedProduct) {
        const index = state.sales.findIndex(
          ({ sale: savedSale }) => savedSale.id === sale.id,
        );
        state.sales[index].sale.records = sale.records.map<SaleRecord>(
          ({ stockItem, ...record }) => {
            // sale might have other pharmacy products that did not change
            if (stockItem.pharmacyProductId !== pharmacyProduct.id) {
              return { ...record, stockItem };
            }

            const id: string = changedStockItemIds.includes(stockItem.id)
              ? (pharmacyProduct?.mappings?.[stockItem.id] as string)
              : stockItem.id;
            return {
              ...record,
              price:
                pharmacyProduct.stockItems.find((si) => si.id === id)
                  ?.salePrice ?? 0, // not sur about this ??
              stockItem: {
                ...stockItem,
                id,
              },
            };
          },
        );
      }
    });
  },
});

function checkIfExist(
  {
    ifExist,
    ifNoExist,
  }: {
    ifExist?: (sale: Sale, index: number) => void;
    ifNoExist?: (sale: Sale) => void;
  },
  { sale, sales }: { sale: Sale | undefined; sales: SaleSlice["sales"] },
) {
  if (!sale) {
    return false;
  }
  const index = sales.findIndex(
    ({ sale: savedSale }) => savedSale.id === sale.id,
  );
  if (index < 0) {
    if (!ifNoExist) {
      return console.warn(
        `Unable to retrieve the sale with this given id : ${sale.id}`,
      );
    }
    ifNoExist(sale);
  } else {
    if (!ifExist) {
      return console.warn("Unable to execute on an existing sale");
    }
    ifExist(sale, index);
  }
}
