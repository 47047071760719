import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";

import { useFirebaseStore } from "@/firebase";
import { Center, Loader } from "@meditect/medibase-components";

import { UserContext } from "./UserContext";

export interface UserProviderProperties {
  children?: React.ReactNode;
}

export const UserProvider = ({
  children,
}: UserProviderProperties): React.ReactElement => {
  const firebase = useFirebaseStore((state) => state.firebaseInstance);
  const auth = getAuth(firebase);
  const currentUser = auth.currentUser;
  const [user, setUser] = useState(currentUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {isLoading && (
        <Center h="100%">
          <Loader />
        </Center>
      )}
      {!isLoading && children}
    </UserContext.Provider>
  );
};
